import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkBack from '../../components/link-back'
import AsideTitle from '../../components/aside/title'
import { AsideLinks, AsideLink } from '../../components/aside/links-list'
import useHashLinks from '../../hooks/useHashLinks'
import {
  StyledContainer,
  StyledArticle,
  StyledAside,
} from '../../components/blog/styledComponents'
import {
  ImageFigure,
  MarkdownText,
  PageTitle,
  VideoFigure,
} from '../../components/blog'

export const query = graphql`
  query ArticleQuery($id: ID!) {
    strapiGraphql {
      article(id: $id) {
        anchorsTitle
        slug
        pageTitle
        items {
          ... on StrapiGraphQL_ComponentContentRichText {
            id
            text
          }
          ... on StrapiGraphQL_ComponentContentUploads {
            id
            media {
              mediaFile {
                childImageSharp {
                  fluid(maxWidth: 700, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
              url
              caption
              alternativeText
            }
          }
          ... on StrapiGraphQL_ComponentContentAnchor {
            id
            anchorHash
            anchorTitle
            targetTitle
          }
        }
        pageSeo {
          keywords
          title
          description
        }
      }
    }
  }
`

export default function Article({ data }) {
  const content = data.strapiGraphql.article
  const anchors = content.items.filter(
    item => typeof item.anchorHash !== 'undefined',
  )

  const { selectedHash, setSelectedHash } = useHashLinks()

  return (
    <Layout>
      <SEO {...content.pageSeo} />

      <StyledContainer>
        <LinkBack to='/blog'>Все записи</LinkBack>

        <PageTitle>{content.pageTitle}</PageTitle>

        <StyledArticle>
          {content.items.map(item => {
            if (typeof item.text !== 'undefined') {
              return <MarkdownText key={item.id} text={item.text} />
            } else if (typeof item.media !== 'undefined') {
              return item.media.map(
                ({ mediaFile, url: key, alternativeText, caption }) => {
                  if (mediaFile.childImageSharp) {
                    return (
                      <ImageFigure
                        key={key}
                        alt={alternativeText}
                        caption={caption}
                        fluid={mediaFile.childImageSharp.fluid}
                      />
                    )
                  } else if (mediaFile.publicURL) {
                    return <VideoFigure key={key} src={mediaFile.publicURL} />
                  }
                },
              )
            } else if (typeof item.anchorHash !== 'undefined') {
              const { anchorHash, targetTitle } = item
              return (
                <h4 id={anchorHash} key={anchorHash}>
                  {targetTitle}
                </h4>
              )
            }
          })}
        </StyledArticle>

        {Boolean(anchors.length) && (
          <StyledAside>
            <AsideTitle>{content.anchorsTitle}</AsideTitle>
            <AsideLinks>
              {anchors.map(({ anchorHash, anchorTitle, id }) => (
                <AsideLink
                  key={id}
                  hash={anchorHash}
                  isActive={anchorHash === selectedHash}
                  onClick={() => setSelectedHash(anchorHash)}
                >
                  {anchorTitle}
                </AsideLink>
              ))}
            </AsideLinks>
          </StyledAside>
        )}
      </StyledContainer>
    </Layout>
  )
}
